.Banner_box {
    max-width: 1240px;
    margin: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(6, 5, 15, 1);
    padding: 50px 30px;
    gap: 60px;
    transition: .4s;
 
    @media (max-width:1095px) {
        gap: 10px;
        }
       
        @media (min-width:545px ) and (max-width:1236px) {
           padding: 50px 30px ;
            }
            @media (max-width:544px) {
                padding: 50px 30px ;
              }
    .Info_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        @media (max-width:1095px) {
          gap: 10px;
          }
        .Title1{
            transition: .4s;
            h1 {
                background: linear-gradient(278.85deg, #CDA5D5 3.36%, #FBE4FF 48.72%, #E8FE74 99.06%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 400;
          font-size: 10px;
          text-align: center;
          transition: .4s;

        
            }
        }
        .Title2 {
            transition: .4s;
            h2 {
                background: linear-gradient(90deg, #D5B1DD 0%, #E8FE74 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 400;
                font-size: 5px;
                text-align: center;
                transition: .4s;
           
          
            }
        }
    }
    .Active_text {
        transform: translateY(30px);

    }

    .Img_box {
        width: 100%;
        // overflow: hidden;
       display: flex;
       justify-content: center;

        img {
            width: 30%;
            transition: .4s;
            aspect-ratio: 1/.5;
            border-radius: 100px;
            cursor: pointer;
        @media (max-width:872px) {
            border-radius: 60px;
        }
        @media (max-width:722px) {
            border-radius: 40px;
        }
   
        }
 
    }
}
