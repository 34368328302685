.Projects_box {
    width: 100%;
   
    height: auto;
    background: rgba(205, 165, 213, 1);

    padding: 90px 0;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 42px;
    @media  (max-width: 1236px) {
       
            padding: 50px 30px;
        
    }
    .Info_box {
        max-width:  1240px;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        h1 {
          color:  rgba(6, 5, 15, 1);
            font-size: 50px;
            font-weight: 400;
            @media (max-width:566px) {
                font-size: 35px;
            }
        }
        span {
            color: rgba(6, 5, 15, 0.4);

            font-size: 30px;
        }
    }
    .Swipper {
        max-width:  1240px;
        width: 100%;
        margin: auto;
        overflow-x: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
gap: 20px;
        padding: 20px 0;
            &>div {
                width: 385px;
                border-radius: 60px;
            height: auto;
               
                padding: 56px 40px ;
                flex-shrink: 0;
                background: rgba(6, 5, 15, 0.05);
                .Img {
                    margin-top: 20px;
                    background-color: rgba(217, 217, 217, 1);
                    width: 100%;
                    aspect-ratio: 1/.9;
                    // img {
                    //     width: 100%;
                    //     height: 100%;
                    //     background-color: red;
                    //  }
        
                }
@media (max-width:904px) {
    width: 300px;
    height: 400px;
}
                h1 {
                   color: rgba(6, 5, 15, 0.7);

                    font-size: 18px;
                    font-weight: 500;
                }

            }
       
          
        }
    
   

}