footer {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
.Footer_box {
    max-width: 1240px;
    margin: auto;
    padding: 100px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    &>div {
        flex: 1 1;
        @media (max-width:921px) {
            flex: none;
        }
    }
    
    &>div:last-child {
        flex: 2 2;
    }
    @media (min-width:545px ) and (max-width:1236px) {
        padding: 100px 10px ;
         }
         @media (max-width:544px) {
             padding: 100px 10px ;
           }
           &>div {
            border-left: 1px solid rgba(255, 255, 255, 0.15);
            padding: 0 50px;
            height: 100%;
            @media (max-width:921px) {
                padding: 0 20px;
            }
           ul {
            display: flex;
            align-items: start;
            justify-content: center;
            flex-direction: column;
            list-style: none;
            gap: 10px;
            &>li:first-child {
                margin-bottom: 25px;
            }
            li {
                font-size: 26px;
                font-weight: 500;
                a {
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 400;
                }
            }}
           }
           .Description_box {
            display: flex;
            align-items: start;
            justify-content: center;
            flex-direction: column;
            gap: 25px;
            p {
                
                color:  rgba(255, 255, 255, 0.5);
                
                font-size: 14px;
                font-weight: 400;
                @media (max-width:921px) {
                    width: 300px;
                }
                span {
                    color: rgba(255, 255, 255, 1);
                    font-size: 14px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
            .Rights {
                color: rgba(255, 255, 255, 0.5);
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
                transition: .4s;
                &:hover {
                    color: rgba(255, 255, 255, 1);
                }
            }
           }
}

           .Bg {
            width: 100%;
            height: 50px;
            background: linear-gradient(278.85deg, #CDA5D5 3.36%, #FBE4FF 48.72%, #E8FE74 99.06%);

           }
}